import { createClient as createSubscriptionClient } from 'graphql-ws'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  createClient,
  gql,
  errorExchange,
  fetchExchange,
  subscriptionExchange,
} from 'urql'
import { pipe, subscribe } from 'wonka'

import Button from './Button'
import { RESET } from './Data.js'
import { makeSetFlowToAction } from './Flow'
import * as helpers from './ToolsHelpers'

let Context = React.createContext({})

let STATUSES = {
  PENDING: 'PENDING',
  DISABLED: 'DISABLED',
  READY: 'READY',
  ERROR: 'ERROR',
}

export function Tools(props) {
  let [value, setValue] = useState({
    appId: '/home/circleci/project/apps/appointments/src',
    status: STATUSES.PENDING,
    client: null,
    instanceId: null,
    useToolsData: new Set(),
  })

  useEffect(() => {
    if (value.status === STATUSES.READY || value.status === STATUSES.ERROR)
      return

    let cancel = false

    async function run() {
      try {
        if (!(await helpers.isEnabled())) return

        let res = await window.fetch('http://192.168.80.3:1111/healthz')
        if (cancel) return
        if (!res.ok) {
          setValue((value) => ({
            ...value,
            client: null,
            status: STATUSES.ERROR,
          }))
          return
        }

        let instanceId = await helpers.getInstanceId()
        if (cancel) return

        let client = createApiClient({
          api: 'http://192.168.80.3:1111/graphql',
          appId: value.appId,
          instanceId,
        })
        if (cancel) return

        setValue((value) => ({
          ...value,
          client,
          instanceId,
          status: STATUSES.READY,
        }))
      } catch (error) {
        setValue((value) => ({
          ...value,
          client: null,
          status: STATUSES.ERROR,
        }))
      }
    }
    run()

    return () => {
      cancel = true
    }
  }, [value.status, value.appId])

  useEffect(() => {
    if (value.status !== STATUSES.READY) return

    let subscription = pipe(
      value.client.subscription(subscriptionToolsCommandByPk, {
        id: value.appId,
        instanceId: value.instanceId,
      }),
      subscribe((result) => {
        let command = result.data?.toolsCommandByPk
        let event = null

        switch (command?.type) {
          case 'setData': {
            event = new helpers.CustomEvent(
              `setData:${command.data.viewPath}:${command.data.context}`,
              { detail: command }
            )
            break
          }

          case 'captureDataStart': {
            setValue((value) => ({
              ...value,
              useToolsData: new Set([
                ...value.useToolsData,
                `${command.data.viewPath}:${command.data.context}`,
              ]),
            }))
            break
          }

          case 'captureDataStop': {
            setValue((value) => ({
              ...value,
              useToolsData: new Set(
                [...value.useToolsData].filter(
                  (item) =>
                    item !== `${command.data.viewPath}:${command.data.context}`
                )
              ),
            }))
            break
          }

          case 'setFlowTo': {
            event = new helpers.CustomEvent(`setFlowTo:${command.data.root}`, {
              detail: command,
            })
            break
          }

          default: {
          }
        }

        if (event) {
          value.client.events.dispatchEvent(event)
        }
      })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [value.status, value.client, value.appId, value.instanceId])

  return (
    <Context.Provider value={value}>
      {value.status === STATUSES.ERROR && (
        <Button
          style={{
            position: 'fixed',
            top: 4,
            left: 4,
          }}
          onClick={() =>
            setValue((state) => ({ ...state, status: STATUSES.PENDING }))
          }
          text="Simple Tools connection failed. Click to try to reconnect."
        />
      )}
      {props.children}
    </Context.Provider>
  )

  function createApiClient({ api, appId, instanceId }) {
    let subscriptionClient = createSubscriptionClient({
      url: api.replace(/^http/, 'ws'),
      lazy: true,
      retryAttempts: 1000,
      connectionParams: { id: appId, instanceId },
      shouldRetry: () => window.navigator?.onLine || true,
      on: {
        error: (error) => {
          console.error({ subscriptionClientError: error })
          setValue((state) => ({ ...state, status: STATUSES.PENDING }))
        },
        closed: (message) => {
          console.log({ subscriptionClientComplete: message })
          setValue((state) => ({ ...state, status: STATUSES.PENDING }))
        },
      },
    })

    let client = createClient({
      url: api,
      exchanges: [
        makeErrorExchange(),
        fetchExchange,
        subscriptionExchange({
          // An operation is an object that has { key, query, variables, context }
          // Hasura only cares about key, query, and variables, which is why I'm
          // ignoring context.
          forwardSubscription: ({ key, query, variables }) => ({
            subscribe: (sink) => ({
              unsubscribe: subscriptionClient.subscribe(
                { key, query, variables },
                sink
              ),
            }),
          }),
        }),
      ],
    })
    client.subscriptionClient = subscriptionClient
    client.events = new helpers.EventTarget()

    return client

    function makeErrorExchange() {
      return errorExchange({
        onError: (error, operation) => {
          let errorContext = {
            graphQLErrors: error.graphQLErrors,
            networkError: error.networkError,
          }

          try {
            let [app, rviewPath] =
              operation.query.definitions[0].name.value.split('__')

            errorContext = {
              ...errorContext,
              type: operation.kind,
              viewPath: `/${rviewPath.replace(/_/g, '/')}`,
              app,
            }
          } catch (_) {
            errorContext.operation = operation
          }
          console.error('simple/tools', { error, errorContext })
        },
      })
    }
  }
}
function useTools() {
  return useContext(Context)
}

export function useToolsFlow({ context: [flowState, flowDispatch], viewPath }) {
  let debounceRef = useRef()
  let tools = useTools()

  // apply Tools commands into the flow
  useEffect(() => {
    if (!tools.client) return

    let event = `setFlowTo:${viewPath}`
    tools.client.events.addEventListener(event, listener)
    return () => tools.client.events.removeEventListener(event, listener)

    function listener(event) {
      flowDispatch(
        makeSetFlowToAction({
          target: event.detail.data.target,
          source: '/Tools',
          data: null,
        })
      )
    }
  }, [flowDispatch, viewPath, tools])

  // relay flow updates to tools
  useEffect(() => {
    if (!tools.client || !viewPath) return

    async function run() {
      await tools.client
        .mutation(mutationUpdateRuntimeApp, {
          id: tools.appId,
          instanceId: tools.instanceId,
          viewPath,
          flow: {
            actions: flowState.actions,
            flow: flowState.flow,
          },
        })
        .toPromise()
    }

    clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(run, 250)
  }, [flowState, tools, viewPath])
}

export function useToolsData({
  context,
  contextKey,
  value: [dataState, dataDispatch],
  viewPath,
}) {
  let debounceRef = useRef()
  let tools = useTools()

  // apply Tools commands into the data
  useEffect(() => {
    if (!tools.client) return

    let viewPathContext = `${viewPath}:${context}`
    if (!tools.useToolsData.has(viewPathContext)) return

    let event = `setData:${viewPathContext}`
    tools.client.events.addEventListener(event, listener)
    return () => tools.client.events.removeEventListener(event, listener)

    function listener(event) {
      dataDispatch({
        type: RESET,
        ...event.detail.data,
      })
    }
  }, [dataDispatch, context, viewPath, tools])

  // relay data updates to tools
  useEffect(() => {
    if (!tools.client) return

    let viewPathContext = `${viewPath}:${context}`
    if (!tools.useToolsData.has(viewPathContext)) return
    if (!viewPath) return

    async function run() {
      await tools.client
        .mutation(mutationUpdateRuntimeApp, {
          id: tools.appId,
          instanceId: tools.instanceId,
          viewPath,
          data: {
            context,
            value: dataState.value,
            _touched: [...dataState._touched],
            _forceRequired: dataState._forceRequired,
            _isSubmitting: dataState._isSubmitting,
          },
        })
        .toPromise()
    }

    clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(run, 250)
  }, [dataState, tools, viewPath, context])

  // clear data in tools when the data provider unmounts
  useEffect(() => {
    if (!tools.client) return
    let viewPathContext = `${viewPath}:${context}`
    if (!tools.useToolsData.has(viewPathContext)) return
    if (!viewPath) return

    return () => {
      async function run() {
        await tools.client
          .mutation(mutationDeleteRuntimeApp, {
            id: tools.appId,
            instanceId: tools.instanceId,
            viewPath,
            data: true,
          })
          .toPromise()
      }
      run()
    }
  }, [tools, viewPath, context])
}

let mutationUpdateRuntimeApp = gql`
  mutation updateRuntimeApp(
    $id: String!
    $instanceId: String!
    $viewPath: String!
    $data: DataState
    $flow: FlowState
  ) {
    updateRuntimeApp(
      id: $id
      instanceId: $instanceId
      viewPath: $viewPath
      data: $data
      flow: $flow
    )
  }
`

let mutationDeleteRuntimeApp = gql`
  mutation deleteRuntimeApp(
    $id: String!
    $instanceId: String!
    $viewPath: String!
    $data: Boolean
    $flow: Boolean
  ) {
    deleteRuntimeApp(
      id: $id
      instanceId: $instanceId
      viewPath: $viewPath
      data: $data
      flow: $flow
    )
  }
`

let subscriptionToolsCommandByPk = gql`
  subscription toolsCommandByPk($id: String!, $instanceId: String!) {
    toolsCommandByPk(id: $id, instanceId: $instanceId) {
      type
      data
    }
  }
`
